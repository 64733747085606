//import { url } from '@/config';
import http from '@/utils/httpRequest'
export const getList = (params) => http({
  url: http.adornUrl('/hbi/category/list'),
  method: 'get',
  params: params
});

export const getObj = (id) => http({
  url: http.adornUrl('/hbi/category/detail'),
  method: 'get',
  params: {
    id
  }
});

export const addObj = (data) => http({
  url: http.adornUrl('/hbi/category/save'),
  method: 'post',
  data: data
});
export const updateObj = (data) => http({
  url: http.adornUrl('/hbi/category/update'),
  method: 'post',
  data: data
});



export const delObj = (id) => http({
  url: http.adornUrl('/hbi/category/remove'),
  method: 'post',
  params: {
    ids: id
  }
});

export function saveOrUpdate(data) {
  if(!data.id) {
    return addObj(data)
  } else {
    return updateObj(data)
  }
}