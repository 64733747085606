<template>
    <el-dialog
            :title="titleMsg"
            :close-on-click-modal="false"
            :visible.sync="visible">
        <el-form v-loading="formLoading" :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px">
            
            <el-form-item label="分组名称" prop="categoryKey">
                <el-input v-model="dataForm.categoryKey" placeholder="分组名称"></el-input>
            </el-form-item>
            <el-form-item label="分组值" prop="categoryValue">
                <el-input v-model="dataForm.categoryValue"  placeholder="分组值"  />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :loading="isOnSubmit">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="formLoading || isOnSubmit">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import {  saveOrUpdate ,  getObj as findById} from '@/api/avueData/category.js'

    export default {
        data () {
            return {
                visible: false,
                isOnSubmit: false,
                formLoading: false,
                titleMsg:'',
                dataForm: {
                    id: null, 
                    categoryValue: '',
                    categoryKey: '', 
                },
                dataRule: {
                    categoryKey: [{ required: true, message: '分组名称不能为空', trigger: 'blur' }],
                    categoryValue: [{ required: true, message: '分组值不能为空', trigger: 'blur' }]
                }
            }
        },
        methods: {
            /** 新增节点 */
            init(id){
                this.visible = true
                this.dataForm.id = id 

                this.$nextTick(()=> {
                    this.$refs.dataForm.resetFields()

                    if(id){
                        this.titleMsg = '添加分组信息'
                        //
                        findById(id).then(({data})=> {
                            if(data && data.code == 0) {
                                this.dataForm.categoryKey = data.data.categoryKey
                                this.dataForm.categoryValue = data.data.categoryValue
                            } else {
                                if(data && data.msg) {
                                    this.$message.error(data.msg)
                                }
                            }

                        })
                    }else{
                        this.titleMsg = '修改分组信息' 
                    }
                })

                
                 
            }, 
            // 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.isOnSubmit = true
                        const data_ = this.dataForm
                        saveOrUpdate(data_).then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 500
                                })
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(data.msg)
                            }
                        }).catch((err) => {
                            this.$message.error(err)
                        }).finally(()=>{
                            this.isOnSubmit = false
                        })
                    }
                })
            }
        }
    }
</script>
