<template>
    <div class="basic-container">
        <el-row :gutter="20" style="margin-bottom: 0px;">
            <el-col :span="10">
                <el-form :inline="true" :model="categoryForm" @submit.native.prevent>
                    <table class="detailTable_search" style="width: 100%">
                        <tr>
                            <td class="titleTd width80px">分组名称</td>
                            <td class="valueTd">
                                <el-input v-model="categoryForm.name" placeholder="使用分组名称过滤列表，字母不区分大小写"
                                          clearable @keyup.enter.native="getCategoryList"></el-input>
                            </td>
                        </tr>
                    </table>
                </el-form> 
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="10">
                  <div class="wxts_msg_search">
                    <span>分组展示</span>
                    <div class="f_t">
                        <el-button-group>
                            <el-button size="mini" @click="getCategoryList()" type="success" :loading="categoryLoading">查询</el-button>
                            <el-button v-if="isAuth('category:write')" type="primary"
                                       @click="addOrUpdateCategoryHandle()">新增 
                            </el-button>
                        </el-button-group>
                    </div>
                </div>
                <div class="data_tree">
                    <el-table :data="categoryList" border v-loading="categoryLoading" highlight-current-row @current-change="categoryChangeHandle"
                          style="width: 100%;">
                        <el-table-column prop="categoryKey" header-align="center" align="center" label="分组名称" >
                        </el-table-column>
                        <el-table-column  header-align="center" align="center" width="120" label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" class="update-button" size="small"
                                           @click="addOrUpdateCategoryHandle(scope.row.id)">修改
                                </el-button>
                                <el-button type="text" class="del-button" size="small" @click="deleteCategoryHandle(scope.row.id)">
                                    删除
                                </el-button> 
                            </template>
                        </el-table-column>

                    </el-table>
                </div>
            </el-col>
            <el-col :span="14">
                <el-form :inline="true" :model="dataForm">
                    <table class="detailTable_search" style="width: 100%">
                        <tr>
                            <td class="titleTd width80px">大屏名称</td>
                            <td class="valueTd">
                                <el-input v-model="dataForm.title" placeholder="配置名称" clearable></el-input>
                            </td>
                            <td class="titleTd width80px">状态</td>
                            <td class="valueTd">
                                <sysDictSelect v-model="dataForm.status" dictType="publish_status" placeholder="请选择发布状态" filterable/>
                            </td>
                        </tr>
                    </table>
                </el-form>

                <div class="wxts_msg_search">
                    <span>大屏模板列表{{categoryName ? ('('+categoryName+')') : ''}}</span>
                    <div class="f_t">
                        <el-button-group>
                            <el-button size="mini" @click="currentChangeHandle(1)" type="success" :disabled="dataListLoading">查询</el-button>
                            <el-button size="mini" :disabled="dataListLoading" type="primary" @click="addOrUpdate()">新增</el-button>
                        </el-button-group>
                    </div>
                </div>
                <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%;">
                    <el-table-column prop="category" header-align="center" align="center" label="分组名称" width="120">
                         <template slot-scope="scope">
                           {{getCategoryName(scope.row.category)}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" header-align="center" align="center" label="大屏名称"  >
                    </el-table-column>
                    <el-table-column prop="status" header-align="center" align="center" label="状态" width="80">
                        <template slot-scope="scope">
                          <sysDict type="publish_status" :value="scope.row.status"/>
                        </template>
                    </el-table-column>
                     
                    <el-table-column  header-align="center" align="center" width="150" label="操作">
                        <template slot-scope="scope">
                          <!--   <el-button type="text" class="update-button" size="small"
                                       @click="addOrUpdate(scope.row.id)">修改
                            </el-button> -->
                            <el-button type="text" class="del-button" size="small" @click="deleteHandle(scope.row.id)">
                                删除
                            </el-button>
                            <el-button type="text" class="update-button" size="small"
                                       @click="editTemplateHandle(scope.row.id,scope.row.password)">编辑
                            </el-button>
                            <el-button                      
                                type="text"
                                class="update-button"
                                size="small"
                                @click="handleViews(scope.row.id)"
                                >预览</el-button
                            >
                            <el-button type="text" class="del-button" size="small" @click="publishHandle(scope.row.id)"
                                       v-if="scope.row.status !== 2">发布
                            </el-button>
                             <el-button type="text" class="del-button" size="small" @click="revokeHandle(scope.row.id)"
                                       v-if="scope.row.status === 2">撤回
                            </el-button>                            
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="sizeChangeHandle" class="mod-footer" 
                        @current-change="currentChangeHandle"
                        :current-page="pageIndex"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageSize"
                        :total="totalCount"
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </el-col>
        </el-row>
        <CategoryAddOrUpdate v-if="addOrUpdateCategoryVisible" ref="category" @refreshDataList="getCategoryList()"/>
       <!--  <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update> -->
        <Create v-if="addOrUpdateVisible" ref="create" @addSuccess="addSuccess"/>   
        <el-dialog
            title="输入密码"
            :close-on-click-modal="false"
            :modal-append-to-body="false"
            :append-to-body="true" 
            :visible.sync="passwordShow" :key="key" customClass="customWidth"
            >
            <el-input v-model="entryPassword"></el-input>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="submit">确定</el-button>
                <el-button type="primary" icon="el-icon-close" @click="closeDialog">关闭</el-button>
            </div>
        </el-dialog>      
    </div>
</template>

<script>
//import container from './container'
import CategoryAddOrUpdate from './category-add-or-update'
import Create from './create_new'
import { getList , delObj,  publish, cancelPublish } from '@/api/avueData/visual.js';
import { getList as getCategoryList ,   delObj as deleteCategory } from '@/api/avueData/category.js'
 

    export default {
        components: {
           Create , CategoryAddOrUpdate
        },
        data() {
            return {
                key: 0,
                tempPassword: '',
                tempId: '',
                entryPassword: '',
                passwordShow: false,
                categoryList: [],
                dataList: [],
                pageIndex: 1,
                pageSize: 10,
                totalCount: 0,
                dataListLoading: false,
                categoryLoading: false,
                categoryId: '',
                categoryName: '',
                 
                categoryForm: {
                    name: '',
                },
                dataForm: {
                    title: null, 
                    status: null
                },
                addOrUpdateCategoryVisible: false,
                addOrUpdateVisible: false,
                publishStatusMap: {'1':'未发布','2':'已发布','3':'已修改未发布'}
            };
        },
        activated() {
            this.getCategoryList();
            this.getDataList()
        },
        methods: {
            submit(){
                if(this.entryPassword != this.tempPassword){
                    this.$message.error('密码输入有误！');
                }else{
                    this.tempPassword = '';
                    let routeUrl = this.$router.resolve({
                        path: '/build/' + this.tempId
                    })
                    window.open(routeUrl.href, '_blank');
                    this.passwordShow = false;
                    this.tempId = '';

                }
            },
            closeDialog(){
                this.key = Math.random();
                this.tempPassword = '';
                this.tempId = '';
                this.passwordShow = false;
            },
            /**
             *  选中某个分组时
             */
            handleCurrentChange(val) {
                this.categoryId = val.id
                this.categoryName = val.name
                //根据分组ID查询配置列表的数据
                this.getDataList();
            },
            getCategoryName(value) {
                 
                for(let i = 0 ; i < this.categoryList.length ; i++) {
                    if(this.categoryList[i].categoryValue == value) {
                        return this.categoryList[i].categoryKey
                    }
                }

                return null
            },
            // 获取分组展示数据列表
            getCategoryList() {
                this.categoryLoading = true
                const params_ = {
                    categoryKey: this.categoryForm.name
                }
                getCategoryList(params_).then(({data}) => {
                    if (data && data.code === 0) {
                        this.categoryList = data.data 
                        
                        this.categoryName = ''; 
                        this.categoryId = ''
                    } else {
                        this.categoryList = []
                        if(data && data.msg)
                            this.$message.error(data.msg)
                    }
                }).catch((err) => {
                    console.error(err)
                }).finally(() => {
                    this.categoryLoading = false
                    
                })
            }, 
            categoryChangeHandle(val) {
                this.categoryId = val.categoryValue
                this.categoryName = val.categoryKey

                this.getDataList()
                
            },
            addOrUpdateCategoryHandle(id) {
                this.addOrUpdateCategoryVisible = true 
                this.$nextTick(()=> {
                     
                    this.$refs.category.init(id)
                })
            },
            deleteCategoryHandle(id) {
                this.$confirm(`确定进行删除操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.categoryLoading = true
                    deleteCategory(id).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000
                            })
                            //刷新配置列表
                            this.getCategoryList()
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).catch((err) => {
                        console.error(err)
                    }).finally(() => {
                        this.categoryLoading = false
                    })
                }) 
            },
            //根据分组ID获取数据
            getDataList() {
                this.dataListLoading = true;
                getList({
                    category: this.categoryId,
                    title: this.dataForm.title,  
                    status: this.dataForm.status,
                    page: this.pageIndex,
                    limit: this.pageSize
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataList = data.data.list
                        this.totalCount = data.data.totalCount
                    } else {
                        this.dataList = []
                        this.totalCount = 0
                    }
                })
                .finally(() => {
                    this.dataListLoading = false;
                });
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageIndex = 1;
                this.getDataList();
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val;
                this.getDataList();
            },
            // 多选 
            // 新增 / 修改 配置列表
            addOrUpdate() {
                if ((this.categoryId == '' || this.categoryId == null) ) {
                    this.$alert('请先选择需要新增配置的分组后再进行新增配置的操作，如无所需的分组信息请先新增加分组信息。', '提示', {
                        confirmButtonText: '确定'
                    });
                    return;
                }
                this.addOrUpdateVisible = true
                this.$nextTick(() => {
                    this.$refs.create.init(this.categoryId, this.categoryName)
                })
                
            },
            addSuccess(id){
              if(id){
                this.addOrUpdateVisible = false;
                this.getDataList()
                this.editTemplateHandle(id)
              }
            },
            // 根据配置列表ID删除
            deleteHandle(id) {
                // var ids = id ? [id] : this.dataListSelections.map(item => {
                //     return item.id;
                // });
                this.$confirm(`确定进行删除操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dataListLoading = true
                    delObj(id).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000
                            })
                            //刷新配置列表
                            this.getDataList()
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).catch((err) => {
                        console.error(err)
                    }).finally(() => {
                        this.dataListLoading = false
                    })
                }) 
            },

            // 发布操作
            publishHandle(id) {

                this.$confirm(`确定进行发布操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.categoryLoading = true
                    publish(id).then(({data}) => {
                        if(data && data.code == 0) {
                            this.$message.success('发布成功');
                            this.getDataList()
                        } else if(data && data.msg) {
                            this.$message.error(data.msg)
                        } 
                    }).finally(() => {
                        this.categoryLoading = false
                    })
                }) 

                
            },

            // 撤回操作
            revokeHandle(id) {
                this.$confirm(`确定进行撤回操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                  this.categoryLoading = true
                  cancelPublish(id).then(({data}) => {
                    if(data && data.code == 0) {
                      this.$message.success('撤销成功');
                      this.getDataList()
                    } else if(data && data.msg) {
                      this.$message.error(data.msg)
                    }
                  }).finally(() => {
                    this.categoryLoading = false
                  })
                }) 
            }, 
            // 模板编辑
            editTemplateHandle(id,password) {
                if(password==""||password==null||password==undefined){
                    let routeUrl = this.$router.resolve({
                        path: '/build/' + id
                    })
                    window.open(routeUrl.href, '_blank');
                }else{        
                    this.entryPassword = '';            
                    this.passwordShow = true;
                    this.tempPassword = password;
                    this.tempId = id;
                }
            },

            // 模板预览
            handleViews(id) {
                let routeUrl = this.$router.resolve({
                    path: '/view/' + id
                })
                window.open(routeUrl.href, '_blank');
            },
        },
        
    };
</script>
<style scoped>
    .data_tree span.custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
    ::v-deep .customWidth{
        width: 30%;
    }
</style>
